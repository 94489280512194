<template>
  <div v-if="bidding">
    <!-- handle error -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <b-col md="6" class="mb-1">
        <b-card title="Bidding" class="h-100 d-flex flex-wrap table-responsive">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ bidding.id }}</td>
              </tr>
              <tr>
                <th class="text-left">Status</th>
                <td class="text-right">
                  <b-badge
                    size="lg"
                    :variant="
                      bidding.status == 'accept'
                        ? 'light-success'
                        : bidding.status == 'canceled'
                        ? 'light-primary'
                        : bidding.status == 'reject'
                        ? 'light-warning'
                        : 'light-secondary'
                    "
                  >
                    {{ bidding.status }}
                  </b-badge>
                </td>
              </tr>
              <tr>
                <th class="text-left">Original Price</th>
                <td class="text-right">{{ bidding.variant.sale_price }}</td>
              </tr>
              <tr>
                <th class="text-left">Offer Price</th>
                <td class="text-right">{{ bidding.offer_price }}</td>
              </tr>

              <tr v-if="bidding.created_at">
                <th class="text-left">Created Date</th>
                <td class="text-right" dir="ltr">
                  {{ bidding.created_at }}
                </td>
              </tr>
              <tr>
                <th class="text-left">payment gateway</th>
                <td class="text-right" dir="rtl">
                  <b-img-lazy width="35" :src="bidding.payment_provider.logo" />
                  <!-- <b-badge class="badge btn-primary mx-1">
                    {{bidding.payment_provider.name }}</b-badge
                  > -->
                </td>
              </tr>

              <tr v-if="bidding.created_at">
                <th class="text-left">Payment Fees</th>
                <td class="text-right" dir="ltr">
                  {{ bidding.payment_data.fees }} %
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
      <b-col md="6" class="mb-1">
        <b-card class="h-100">
          <div class="d-flex justify-content-center">
            <b-button
              v-if="this.bidding.status == 'pending'"
              v-b-modal.modal-update-status
              block
              variant="primary mr-1 mt-1"
            >
              Change Status
            </b-button>
          </div>
          <hr />
          <div
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Created at</h6>
              <h3 class="mb-0">
                {{ bidding.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">Updated at</h6>
              <h3 class="mb-0">
                {{ bidding.updated_at }}
              </h3>
            </div>
          </div>
          <b-alert
            v-height-fade.appear
            fade
            :show="updateStatusShowDismissibleAlert"
            @dismissed="updateStatusShowDismissibleAlert = false"
            variant="danger"
          >
            <h4 class="alert-heading">Alert</h4>
            <div class="alert-body">
              <ul
                v-for="(values, index) in updateStatusErrorsBack"
                :key="index"
              >
                <li v-for="(value, index) in values" :key="index">
                  {{ value }}
                </li>
              </ul>
            </div>
          </b-alert>
          <!-- Update Orer To Delivered-->
          <b-modal
            style="height: 700px !important"
            id="modal-update-status"
            cancel-variant="outline-secondary"
            @ok="changeStatus"
            ok-title="Ok"
            cancel-title="Close"
            centered
            title="Change Status"
          >
            <validation-observer ref="simpleRules" tag="form">
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="status"
                    rules="required"
                  >
                    <b-form-group
                      label="Status"
                      label-for="status"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="status"
                        v-model="updateStatusSelect"
                        :state="updateStatusSelect === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="ChangeStatusOptions"
                        :selectable="
                          (option) => !option.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </validation-observer>
          </b-modal>
        </b-card>
      </b-col>

      <!-- Seller Data -->
      <b-col class="mb-1" md="6">
        <b-card
          title="Seller Details"
          class="h-100 d-flex flex-wrap table-responsive"
        >
          <router-link
            class="ml-1 position-absolute"
            style="top: 20px; right: 25px"
            :to="{ path: '/User/Show/' + bidding.seller_user.id }"
          >
            <img :src="require('@/assets/images/icons/show.svg')" />
          </router-link>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">Seller Code</th>
                <td class="text-right">
                  {{ bidding.seller_user.code }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Seller Name</th>
                <td class="text-right">
                  {{ bidding.seller_user.firstname }}
                  {{ bidding.seller_user.lastname }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Seller Email</th>
                <td class="text-right">
                  {{ bidding.seller_user.email }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>

      <!-- Buyer Data -->
      <b-col class="mb-1" md="6">
        <b-card
          title="Buyer Details"
          class="h-100 d-flex flex-wrap table-responsive"
        >
          <router-link
            class="ml-1 position-absolute"
            style="top: 20px; right: 25px"
            :to="{ path: '/User/Show/' + bidding.buyer_user.id }"
          >
            <img :src="require('@/assets/images/icons/show.svg')" />
          </router-link>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">Buyer Code</th>
                <td class="text-right">
                  {{ bidding.buyer_user.code }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Buyer Name</th>
                <td class="text-right">
                  {{ bidding.buyer_user.firstname }}
                  {{ bidding.buyer_user.lastname }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Buyer Email</th>
                <td class="text-right">
                  {{ bidding.buyer_user.email }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>

      <b-col cols="12">
        <b-card title="Variant">
          <b-table
            id="VariantsTable"
            ref="VariantsTable"
            striped
            hover
            responsive
            show-empty
            class="position-relative"
            :items="variants"
            :fields="variantFields"
          >
            <template #cell(status)="data">
              <b-badge
                :variant="
                  data.value == 'enabled'
                    ? 'light-primary'
                    : data.value == 'sold_out'
                    ? 'light-success'
                    : data.value == 'review' || data.value == 'confirmed'
                    ? 'light-info'
                    : 'light-secondary'
                "
              >
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(product_name)="data">
              <div class="text-nowrap">{{ data.value }}</div>
            </template>

            <template #cell(created_at)="data">
              <div class="text-nowrap">{{ formatDate(data.value) }}</div>
            </template>
            <template #cell(updated_at)="data">
              <div class="text-nowrap">{{ formatDate(data.value) }}</div>
            </template>
            <template #cell(actions)="items">
              <b-button
                style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                class="btn-icon rounded-circle"
                :to="{
                  path:
                    (items.item.is_new ? '/New' : '/Used') +
                    '/ShowProduct/variant/show/' +
                    items.item.id,
                }"
                variant="success"
              >
                <feather-icon
                  style="width: 18px; height: 18px"
                  icon="EyeIcon"
                />
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card title="Payment Sessions">
          <b-table
            id="VariantsTable"
            ref="VariantsTable"
            striped
            hover
            responsive
            show-empty
            class="position-relative"
            :items="bidding.payment_sessions"
            :fields="paymentSessionFields"
          >
            <template #cell(status)="data">
              <b-badge
                :variant="
                  data.value == 'pending'
                    ? 'light-primary'
                    : data.value == 'delivered'
                    ? 'light-success'
                    : data.value == 'failed'
                    ? 'light-danger'
                    : 'light-secondary'
                "
              >
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(created_at)="data">
              <div class="text-nowrap">{{ formatDate(data.value) }}</div>
            </template>
            <template #cell(updated_at)="data">
              <div class="text-nowrap">{{ formatDate(data.value) }}</div>
            </template>

            <template #cell(actions)="items">
              <div class=" text-center d-flex p-1">
                <!-- Show Order -->
                <b-button
                  v-if="authPermissions.includes('show')"
                  variant="light-danger"
                  type="submit"
                  class="my-1 mx-0  pt-1 px-1"
                  :to="{ path: '/paymentSessions/show/' + items.item.id }"
                >
                
                    <img width="30" :src="require('@/assets/images/icons/eye.png')" />
                </b-button>
         
                <b-button
                  v-if="authPermissions.includes('show') && items.item.status == 'pending'"
                  variant="light-danger"
                  type="submit"
                  class="my-1 mx-0 pt-1 px-1"
                  :to="{ path: '/paymentSessions/realtime/show/' + items.item.id }"
                >

                    <img :src="require('@/assets/images/icons/live-streaming.svg')" />
                  </b-button>
               
                  
                <!-- </b-button> -->
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
  
<script>

import { required } from '@validations'
export default {
  components: {
  },

  data() {
    return {
      bidding: null,
      required,
      errors_back: null,
      showDismissibleAlert: false,
      updateStatusErrorsBack: [],
      updateStatusShowDismissibleAlert: false,
      authPermissions: [],
      variants: [],
      updateStatusSelect: null,
      ChangeStatusOptions: ['accept', 'canceled', 'reject', 'archived'],
      variantFields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'product_name',
          label: 'Name',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'original_price',
          label: 'Original Price',
        },

        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      // table fields
      paymentSessionFields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'Code',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'total_paid',
          label: 'Total',
        },
        {
          key: 'margin_amount',
          label: 'Margin Amount',
        },
        {
          key: 'payout_amount',
          label: 'Payout Amount',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    this.id = this.$route.params.id
    this.fetchData()
  },
  methods: {
    formatDate(date) {
      const dateObject = new Date(date);
      return dateObject.toLocaleString(); 
    },
    fetchData() {
      axios
        .get('bidding-requests/' + this.id)
        .then((result) => {
          this.bidding = result.data.data
          this.variants.push(this.bidding.variant)
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    changeStatus() {
      this.updateStatusErrorsBack.length = 0
      this.updateStatusShowDismissibleAlert = false

      if (this.updateStatusSelect == null) {
        this.updateStatusErrorsBack.length = 0
        this.updateStatusErrorsBack.push({
          error: 'new status required',
        })
        this.updateStatusShowDismissibleAlert = true
        console.log(this.updateStatusShowDismissibleAlert)
        console.log(this.updateStatusErrorsBack)
        return
      }

      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to Change Status.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .put('bidding-requests/' + this.bidding.id + '/changeStatus', {
                status: this.updateStatusSelect,
              })
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.bidding = result.data.data
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.updateStatusErrorsBack.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.updateStatusErrorsBack.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.updateStatusErrorsBack = err.response.data.data
                  }
                  this.updateStatusShowDismissibleAlert = true
                } else {
                  this.updateStatusErrorsBack = []
                  this.updateStatusErrorsBack.push({
                    error: 'internal server error',
                  })
                  this.updateStatusShowDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
  
  <style scoped>
.cardH {
  margin-bottom: 0px;
}
</style>
  